<template>
  <div class="image">
  </div>
</template>

<script>
export default {
  name: "Background",
};
</script>
<style lang="scss" scoped>
.image {
  position: fixed;
  width: 100%;
  height: 100%;

  background-image: url("../assets/matelas-background.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;

  // background: url('https://www.equipaliterie.fr/wp-content/uploads/2021/01/ambiance-1600x800-ELEGANCE-1600x630-1.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  filter: blur(8px);
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);

  transform: scale(1.11);
  z-index: -1;
}
</style>